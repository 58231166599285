<template>
  <v-container>
    <v-row class="pt-10">
      <v-col cols="12">
        <h1 class="turquesa--text display-2 pb-5">CONOCENOS + INSCRIBITE + PARTICIPA</h1>
        <h2 class="display-1">SEGUI LOS PASOS DE LA SUBASTA</h2>
      </v-col>
      <arrow-separator />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Conocenos',
  components: {
    ArrowSeparator: () => import('@/views/components/ArrowSeparator')
  }
}
</script>

<style>

</style>